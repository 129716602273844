/* styles.css */

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    /* Add other general styles */
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* Add styles for the header */
}

.searchBar {
    /* Add styles for the search bar */
}

.categories {
    margin-top: 30px;
    /* Add styles for the categories section */
}

.categories h2 {
    /* Add styles for the category section header */
}

.categoryList {
    list-style: none;
    display: flex;
    justify-content: space-between;
    /* Add styles for the category list */
}

.categoryList li {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    /* Add styles for individual category items */
}

.categoryList li img {
    width: 180px;
    height: 150px;
    /* Add styles for category images */
}

.exploreAds {
    margin-top: 50px;
    /* Add styles for the explore ads section */
}

.exploreAds h2 {
    /* Add styles for the explore ads section header */
}

.itemList {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 20px;
    /* Add styles for the item list */
}

.item {
    /* Add styles for individual items */
}

.item img {
    width: 100%;
    height: auto;
    /* Add styles for item images */
}


/* Add more styles as needed */


/* Updated styles.css */


/* ... (existing styles remain the same) */

.menu {
    text-align: center;
    margin-bottom: 20px;
}

.menu ul {
    list-style: none;
    padding: 0;
}

.menu ul li {
    display: inline;
    margin-right: 20px;
}

.menu ul li a {
    text-decoration: none;
    color: #333;
    font-weight: bold;
    font-size: 16px;
}

.menu ul li a:hover {
    color: #555;
}
